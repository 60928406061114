import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';

import {Subject} from 'rxjs';

import {LoginService} from '../../../../pages/login/login.service';
import {AppService, UserService} from '../../../../shared/service/user.service';

import {ConfirmService} from '../../../../shared/components/confirm/confirm.service';

@Injectable({
  providedIn: `root`
})
export class CertificatedService {

  readonly errorSubject: Subject<any>;

  constructor(
    private http: HttpClient,
    private backend: HttpBackend,
    private confirmService: ConfirmService,
    private loginService: LoginService
  ) {
    this.http = new HttpClient(backend);

    this.errorSubject = new Subject<any>();
  }

  run(data: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loginService.getApiKey().subscribe(({token}) => {
        data.loginKey = token;
        data.csrfToken = AppService.get('MOQUI_SESSION_TOKEN');
        data.taxCode = data.taxCode || UserService.getUser().partyTaxId || '';
        // data.taxCode = '';

        this.http.post('http://localhost:24038/certificated', data).subscribe({
          next: (response: any) => {
            if (response.errorCode === '0') {
              resolve();
            } else {
              this.confirmService.setConfirmDialog({
                message: response.errors,
                header: 'Ký điện tử không thành công',
                icon: 'pi pi-exclamation-triangle',
                rejectLabel: 'Đóng',
                acceptVisible: false
              });
              reject();
            }
          }, error: (error) => {
            this.errorSubject.next(error);
            reject();
          }
        })
      });
    });
  }

}
