import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

import {Col, ColGroup} from '../../../../shared/model';

import {UserService} from '../../../../shared/service/user.service';

@Component({
  selector: 'app-iam-table',
  templateUrl: './iam-table.component.html'
})
export class IamTableComponent implements OnChanges {

  @Input() header?: string;
  @Input() dataKey?: string;
  @Input() colGroups?: Array<ColGroup>;
  @Input() cols: Array<Col> = new Array<Col>();
  @Input() dynamicColumns?: boolean;
  @Input() lazy: boolean = true;

  @Input() indexColumnWidth?: number;
  @Input() actionColumnWidth?: number;

  @Input() items: Array<any> = new Array<any>();
  @Input() totalRecords: number = 0;
  @Input() loadLazy: any;

  @Input() selectionMode: string = 'single';
  @Input() onSelectionChange: any;
  @Input() tableClass?: string;
  @Input() onRowDbClick: any;

  localColKey?: string;
  localCols: Array<Col> = new Array<Col>();

  selectedItem: any;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
    if (changes['cols'] && this.dynamicColumns) {
      if (this.cols && this.cols.length) {

        this.localColKey = btoa(encodeURIComponent(JSON.stringify(this.cols)));
        let localCols: any = UserService.get(this.localColKey as any);
        if (localCols) {
          localCols = JSON.parse(localCols);
          if (localCols && localCols.length) {
            this.localCols = new Array<Col>();
            this.cols.forEach((col) => {
              let _col = Object.assign({}, col);
              localCols.forEach((localCol: any) => {
                if (localCol.field === _col.field) {
                  Object.assign(_col, localCol);
                }
              });
              this.localCols.push(_col);
            });
            this.localCols.sort((a, b) => {
              return (a.index || 0) - (b.index || 0)
            });
          }
        } else {
          this.resetCols();
        }
      }
    }
    if (changes['selectionMode']) {
      this.selectedItem = [];
    }
  }

  init(): void {
    if (this.colGroups && this.colGroups.length) {
      this.dynamicColumns = false;
    } else if (this.dynamicColumns === undefined) {
      this.dynamicColumns = true;
    }
    if (!this.dynamicColumns) {
      this.localCols = this.cols;
    }
  }

  onColResize(event: any): void {
    if (event) {
      const {delta, element} = event;
      if (element.id) {
        const field = element.id.substring(3);
        this.localCols.forEach((col) => {
          if (col.field === field) {
            col.width += delta;
          }
        });
        this.saveLocalCols();
      }
    }
  }

  resetCols(): void {
    if (this.cols && this.cols.length) {
      this.localCols = new Array<Col>();
      this.cols.forEach((col, index) => {
        col.index = index;
        this.localCols.push(Object.assign({}, col));
      });
      this.saveLocalCols();
    }
  }

  saveLocalCols(): void {
    if (this.localColKey) {
      this.localCols.forEach((col, index) => {
        col.index = index;
      });
      UserService.set(this.localColKey as any, JSON.stringify(this.localCols));
    }
  }

  setSelectedItem(rowData: any): void {
    if (this.selectionMode === 'multiple') {
      this.selectedItem = [rowData];
    } else {
      this.selectedItem = rowData;
    }
  }

  selectionChange(): void {
    if (this.onSelectionChange) {
      this.onSelectionChange(this.selectedItem);
    }
  }

  _onRowDbClick(rowData: any): void {
    this.onRowDbClick && this.onRowDbClick(rowData);
  }

}
