import {UserService} from './user.service';

export class AuthService {

  static user: any;
  static privilege: Array<any>;

  constructor() {
  }

  static init(): void {
    this.user = UserService.getUser();
    const privilege = UserService.get('PRIVILEGE');
    if (privilege) {
      this.privilege = JSON.parse(privilege);
      // console.log(this.privilege);
    }
  }

  static hasRoles(roles: Array<string>): boolean {
    return roles.includes(this.user.roleTypeId);
  }

  static hasPrivilege(screenId: string, artifactId: string, permission: string): boolean {
    let has = false;
    if (this.privilege && this.privilege.length > 0 && screenId && artifactId && permission) {
      for (const p of this.privilege) {
        if (p && p.screenId === screenId && p.artifacts && p.artifacts.length > 0) {
          for (const artifact of p.artifacts) {
            if (artifact && artifact.artifactId === artifactId && artifact.permissions && artifact.permissions.length > 0) {
              if (artifact.permissions.includes('ALL') || artifact.permissions.includes('BASE')) {
                has = true;
              } else {
                has = artifact.permissions.includes(permission);
              }
              break;
            }
          }
          break;
        }
      }
    }
    return has;
  }

}
