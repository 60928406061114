<form [formGroup]="form">
  <div class="p-fluid p-formgrid p-grid detail">
    <div class="p-field p-col-12 p-md-3">
      <label for="purchasedQuantity">{{ 'Số lượng đã mua'|translate }}</label>
      <p-inputNumber locale="vi-VN" id="purchasedQuantity" formControlName="purchasedQuantity"></p-inputNumber>
    </div>
    <div class="p-field p-col-12 p-md-3">
      <label for="usedQuantity">{{ 'Số lượng đã sử dụng'|translate }}</label>
      <p-inputNumber locale="vi-VN" id="usedQuantity" formControlName="usedQuantity"></p-inputNumber>
    </div>
    <div class="p-field p-col-12 p-md-5">
      <label for="purchasedTimePeriod">{{ 'Thời gian mua'|translate }}</label>
      <p-inputNumber locale="vi-VN" id="purchasedTimePeriod" formControlName="purchasedTimePeriod"></p-inputNumber>
    </div>
    <div class="p-field p-col-12 p-md-1 p-pt-5">
      <span *ngIf="form.get('timeUomId')?.value">({{ form.get('timeUomId')?.value|translate }})</span>
    </div>
    <div class="p-field p-col-12 p-md-3">
      <label for="effectiveDate">{{ 'Ngày bắt đầu sử dụng'|translate }}</label>
      <p-calendar id="effectiveDate" formControlName="effectiveDate" dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="p-field p-col-12 p-md-3">
      <label for="expireDate">{{ 'Ngày hết hạn'|translate }}</label>
      <p-calendar id="expireDate" formControlName="expireDate" dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="p-field p-col-12">
      <p-table [value]="form.get('items')?.value" styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines">
        <ng-template pTemplate="header">
          <tr>
            <th [width]="40" class="p-text-center">#</th>
            <th>Gói</th>
            <th>Ghi chú</th>
            <th [width]="180">Ngày bắt đầu</th>
            <th>Đã sử dụng</th>
            <th>{{ 'Status'|translate }}</th>
            <th [width]="180"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
          <tr>
            <td class="p-text-center">{{ (index + 1)|number }}</td>
            <td>{{ rowData['productName'] }}</td>
            <td>{{ rowData['comments'] }}</td>
            <td>{{ rowData['effectiveDate']|date:'dd/MM/yyyy HH:mm:ss' }}</td>
            <td class="p-text-right">{{ rowData['issuedQuantity']|number }}</td>
            <td>{{ rowData['status'] }}</td>
            <td>
              <ng-container *ngIf="rowData['statusId'] === 'PpInactive'">
                <p-button label="Kích hoạt" styleClass="p-button-raised p-button-sm" (onClick)="activate(rowData)"></p-button>
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="p-field p-col-12 p-mt-4">
      <h5>Danh sách yêu cầu mua gói dịch vụ</h5>
    </div>
    <div class="p-field p-col-12">
      <p-table [value]="requests" styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines">
        <ng-template pTemplate="header">
          <tr>
            <th [width]="40" class="p-text-center">#</th>
            <th [width]="160">Loại yêu cầu</th>
            <th [width]="150">Mã gói</th>
            <th>Tên gói</th>
            <th [width]="110">Ngày yêu cầu</th>
            <th>Người yêu cầu</th>
            <th [width]="150">{{ 'Status'|translate }}</th>
            <th [width]="110">Đã sử dụng</th>
            <th [width]="100">Còn lại</th>
            <th [width]="110">Ngày hiệu lực</th>
            <th [width]="110">Ngày hết hạn</th>
            <th [width]="150">Ghi chú</th>
            <th [width]="100"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
          <tr>
            <td class="p-text-center">{{ (index + 1)|number }}</td>
            <td>{{ rowData['requestTypeEnum'] }}</td>
            <td>{{ rowData['productPseudoId'] }}</td>
            <td>{{ rowData['itemDescription'] }}</td>
            <td>{{ rowData['requestDate']|date:'dd/MM/yyyy' }}</td>
            <td>{{ rowData['filedByParty'] }}</td>
            <td>{{ rowData['status'] }}</td>
            <td class="p-text-right">{{ rowData['usedQuantity']|number }}</td>
            <td class="p-text-right">{{ rowData['quantityOnHandTotal']|number }}</td>
            <td>{{ rowData['receivedDate']|date:'dd/MM/yyyy' }}</td>
            <td>{{ rowData['activationValidThru']|date:'dd/MM/yyyy' }}</td>
            <td>{{ rowData['description'] }}</td>
            <td>
              <ng-container *ngIf="rowData['statusId'] === 'ReqSubmitted'">
                <p-button icon="pi pi-times" label="Hủy" styleClass="p-button-raised p-button-warning p-button-sm" (onClick)="cancelRequest(rowData)"></p-button>
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
</form>
