import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';

import {ItemsService} from './items.service';
import {ToastService} from '../../../shared/components/toast/toast.service';
import {ConfirmService} from '../../../shared/components/confirm/confirm.service';

@Component({
  selector: 'app-consume-review',
  templateUrl: './consume-review.component.html'
})
export class ConsumeReviewComponent implements OnInit {

  form = this.fb.group({
    purchasedTimePeriod: [{value: '', disabled: true}],
    timeUomId: [{value: '', disabled: true}],
    timeUom: [{value: '', disabled: true}],
    purchasedQuantity: [{value: '', disabled: true}],
    usedQuantity: [{value: '', disabled: true}],
    expireDate: [{value: '', disabled: true}],
    effectiveDate: [{value: '', disabled: true}],
    items: [{value: '' as any, disabled: true}]
  });

  requests: Array<any> = new Array<any>();

  constructor(
    private fb: FormBuilder,
    private itemsService: ItemsService,
    private toastService: ToastService,
    private confirmService: ConfirmService
  ) {
  }

  ngOnInit(): void {
    this.load();
    this.loadRequests();
  }

  load(): void {
    this.itemsService.getPackageMgrInfo().subscribe((response) => {
      if (response.effectiveDate) {
        response.effectiveDate = new Date(response.effectiveDate);
      }
      if (response.expireDate) {
        response.expireDate = new Date(response.expireDate);
      }
      this.form.patchValue(response);
    });
  }

  activate(item: any): void {
    this.confirmService.setConfirmDialog({
      message: `Kích hoạt gói ${item.productName}?`,
      header: 'Kích hoạt',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.itemsService.activatePackageMgrInfo(item['productId']).subscribe(() => {
          this.toastService.success();
          this.load();
        });
      }
    });
  }

  loadRequests(): void {
    this.itemsService.getRequests().subscribe(({listData}) => {
      this.requests = listData;
    });
  }

  cancelRequest(item: any): void {
    this.confirmService.setConfirmDialog({
      message: `Huỷ yêu cầu ${item.requestTypeEnum} ${item.itemDescription}?`,
      header: 'Huỷ yêu cầu',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.itemsService.cancelRequest(item['requestId'], item.lastUpdatedStamp).subscribe(() => {
          this.toastService.success();
          this.loadRequests();
        });
      }
    });
  }

}
