import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private readonly url = 'iam-common/notifications';

  constructor(private http: HttpClient) {
  }

  getList(limit?: number): Observable<any> {
    return this.http.get(`${this.url}/list?limit=${limit || ''}`);
  }

  getCount(): Observable<any> {
    return this.http.get(`${this.url}/count`);
  }

  markViewed(): Observable<any> {
    return this.http.put(`${this.url}/mark-viewed`, {});
  }

  markTouched(): Observable<any> {
    return this.http.put(`${this.url}/mark-touched`, {});
  }

}
