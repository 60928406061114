import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';

import {MenuItem} from 'primeng/api';

import {BreadcrumbService} from './breadcrumb.service';
import {TranslateService} from '../../../../shared/translate/translate.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  items: Array<MenuItem> = new Array<MenuItem>();

  home: MenuItem = {icon: 'pi pi-home', routerLink: '/', state: {slug: '-'}};

  buttons?: Array<MenuItem>;

  breadcrumbSubscription?: Subscription;
  buttonsSubscription?: Subscription;

  constructor(
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.breadcrumbSubscription = BreadcrumbService.getBreadcrumb().subscribe((breadcrumb) => {
      this.items = [];
      if (breadcrumb) {
        const items = breadcrumb.split('(-)');
        items.forEach(item => {
          if (item) {
            this.items?.push({label: this.translateService.get(item)});
          }
        });
      } else {
        this.items.push({label: 'VININVOICE'});
      }
    });

    this.buttonsSubscription = BreadcrumbService.getButtons().subscribe((buttons) => {
      this.buttons = buttons;
    });
  }

  ngOnDestroy(): void {
    if (this.breadcrumbSubscription) {
      this.breadcrumbSubscription.unsubscribe();
    }
    if (this.buttonsSubscription) {
      this.buttonsSubscription.unsubscribe();
    }
  }

}
