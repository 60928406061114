import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';

import {ItemsService} from './items.service';
import {validateAllFormFields} from '../../../shared/components/validator/validator.service';
import {GeosService} from '../../../shared/service/geos.service';
import {ToastService} from '../../../shared/components/toast/toast.service';

import {AppSubject} from '../../../shared/app-subject';
import {Subscription} from 'rxjs';

import {IamComponent} from '../../abstract/IamComponent';
import {ConfirmService} from '../../../shared/components/confirm/confirm.service';

import {UserService} from '../../../shared/service/user.service';

@Component({
  selector: 'app-organization-info',
  templateUrl: './items.component.html'
})
export class ItemsComponent extends IamComponent implements OnInit, OnDestroy {

  submitted: boolean = false;
  form = this.fb.group({
    partyTaxId: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.@-]*$/)]],
    partyName: ['', Validators.required],
    webAddress: [''],
    emailAddress: [''],
    phoneNumber: [''],
    faxNumber: [''],
    address1: ['', Validators.required],
    stateProvinceGeoId: ['', Validators.required],
    countyGeoId: ['', Validators.required],
    taxAuthorityId: ['', Validators.required],
    taxAuthorityName: [''],
    bankAccounts: ['' as any],
    deletePaymentMethodIds: ['' as any],

    representativeName: [''],
    representativeJobTitle: [''],
    repIdentityCard: [''],
    repIdCardIssuedDate: [''],
    repIdCardIssuedBy: [''],
    representativePhoneNumber: [''],
    representativeEmail: [''],

    customerStatusId: [''],
    customerStatusCode: [''],
    establishedDate: [''],
    decreeAndCircularUsing: [{value: '', disabled: true}],

    tenNganhNgheKD: [''],
    soHdongDlyThue: [''],
    ngayHdongDlyThue: [''],
    nvienDlyThue: [''],
    cchiDlyThue: [''],
    tenDviPthuoc: [''],
    mstDviPthuoc: [''],
    ct11ProvinceGeoId: [''],
    ct11CountyGeoId: [''],
    ct11WardName: ['']
  });
  provinces: Array<any> = new Array<any>();

  creatingBankAccount?: boolean;
  bankAccountForm = this.fb.group({
    _id: [''],
    bankAccount: ['', Validators.required],
    bankName: ['', Validators.required]
  });

  updating?: boolean;

  decreeCircular?: string;
  decreeCircularSubscription?: Subscription;

  logoData: any;

  tab?: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private itemsService: ItemsService,
    private geosService: GeosService,
    private toastService: ToastService,
    private confirmService: ConfirmService
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.tab = params['tab'];
    });
    this.logoData = UserService.get('user-logo');
    this.decreeCircularSubscription = AppSubject.getDecreeCircular().subscribe((decreeCircular) => {
      if (decreeCircular) {
        this.decreeCircular = decreeCircular;
      }
    });

    const init = [];
    init.push(new Promise<void>((resolve) => {
      this.geosService.getProvinces().subscribe(({listData}) => {
        listData.forEach((item: any) => {
          this.provinces.push({value: item.toGeoId, label: item.geoName});
        });
        resolve();
      });
    }));
    Promise.all(init).then(() => {
      this.load();
    });
  }

  ngOnDestroy(): void {
    if (this.decreeCircularSubscription) {
      this.decreeCircularSubscription.unsubscribe();
    }
  }

  load(): void {
    this.itemsService.get().subscribe(response => {
      this.form.reset();

      if (response.ngayHdongDlyThue) {
        response.ngayHdongDlyThue = new Date(response.ngayHdongDlyThue);
      }

      this.form.patchValue(response);
      this.form.disable();
    });
  }

  createBankAccount(): void {
    this.bankAccountForm.reset();
    this.creatingBankAccount = true;
  }

  updateBankAccount(rowData: any): void {
    this.bankAccountForm.reset();
    if (rowData.paymentMethodId) {
      rowData._id = rowData.paymentMethodId;
    }
    this.bankAccountForm.patchValue(rowData);

    this.creatingBankAccount = true;
  }

  deleteBankAccount(event: Event, rowData: any): void {
    this.confirmService.setConfirmPopup({
      target: event.target,
      message: 'Xóa tài khoản ngân hàng ra khỏi danh sách?',
      accept: () => {
        let bankAccounts = this.form.get('bankAccounts')?.value;
        bankAccounts.forEach((bankAccount: any, index: number) => {
          if (bankAccount._id === rowData._id) {
            bankAccounts.splice(index, 1);
          }
        });
        this.form.get('bankAccounts')?.setValue(bankAccounts);

        if (rowData.paymentMethodId) {
          let deletePaymentMethodIds = this.form.get('deletePaymentMethodIds')?.value || [];
          deletePaymentMethodIds.push(rowData.paymentMethodId);
          this.form.get('deletePaymentMethodIds')?.setValue(deletePaymentMethodIds);
        }
      }
    });
  }

  onBankAccountSubmit(): void {
    if (this.bankAccountForm.valid) {

      const data: any = this.bankAccountForm.value;

      let bankAccounts = this.form.get('bankAccounts')?.value;
      if (!bankAccounts) {
        bankAccounts = [];
      }

      if (data._id) {
        for (const bankAccount of bankAccounts) {
          if (bankAccount._id === data._id) {
            Object.assign(bankAccount, data);
            break;
          }
        }
      } else {
        data._id = Date.now() + Math.random() * 1000;
        bankAccounts.push(data);
      }
      this.form.get('bankAccounts')?.setValue(bankAccounts);
      this.creatingBankAccount = false;
    }
  }

  update(): void {
    this.updating = true;

    this.form.enable();
  }

  cancelUpdate(): void {
    this.updating = false;

    this.form.disable();
  }

  onSubmit(): void {
    if (!this.submitted && validateAllFormFields(this.form)) {
      this.submitted = true;

      this.itemsService.set(this.form.value).subscribe((response) => {
        this.submitted = false;
        if (response && response.customerPartyId) {
          this.toastService.success();
          this.cancelUpdate();
        } else {
          this.toastService.error();
        }
      }, () => {
        this.submitted = false;
      });
    }
  }

  onUploadLogo(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (file && file.type) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.logoData = reader.result;
          UserService.set('user-logo', `${reader.result}`);
        }
        reader.readAsDataURL(file);
      }
    }
  }

  onDeleteLogo(): void {
    this.logoData = '';
    UserService.set('user-logo', '');
  }

}
