import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-attachment-viewer',
  templateUrl: './attachment-viewer.component.html'
})
export class AttachmentViewerComponent {

  @Input() display?: number;
  @Input() html?: string;

  constructor() {
  }

  onShow(): void {
    const preview: any = document.getElementById('attachmentViewer');
    preview && preview.contentWindow && preview.contentWindow.document.write(this.html);
  }

  print(): void {
    const preview: any = document.getElementById('attachmentViewer');
    preview && preview.contentWindow && preview.contentWindow.print();
  }

}
